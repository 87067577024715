<script setup>
import { onMounted } from "vue";
import { provideI18n } from "./i18nPlugin";
import AdminNavBar from "./components/common/AdminNavBar.vue";
import cn from "./i18n/cn";
import en from "./i18n/en";
import jp from "./i18n/jp";
import cookie from "./cookie";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import AdminLogin from "./views/AdminLogin.vue";

import User from "./api/pos/auth/user";
import router from "./router";
let userApi = new User();

const toast = useToast();
let cookieSetting = new cookie();
let lang = cookieSetting.getCookie("lang");
const store = useStore();

if (lang != "") {
  store.commit("set_lang", lang);
}
// else {
//   store.commit("set_lang", "jp");
// }

const mounted = onMounted(async () => {});

provideI18n({
  locale: store.state.lang,
  messages: {
    cn: cn,
    en: en,
    jp: jp,
  },
});
</script>

<template>
  <div v-if="router.currentRoute.value.path.indexOf('/_1231admin') != -1">
    <AdminLogin class="login" v-if="store.state.admin_user == null" />
    <div v-else>
      <AdminNavBar v-if="store.state.admin_user != null" />
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div v-if="router.currentRoute.value.path.indexOf('/company-admin') != -1">
    <AdminLogin class="login" v-if="store.state.company_admin_user == null" />
    <div v-else>
      <AdminNavBar v-if="store.state.company_admin_user != null" />
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div v-if="router.currentRoute.value.path.indexOf('/company-user') != -1">
    <AdminLogin class="login" v-if="store.state.company_user == null" />
    <div v-else>
      <AdminNavBar v-if="store.state.company_user != null" />
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div
    v-if="
      router.currentRoute.value.path.indexOf('/_1231admin') == -1 &&
      router.currentRoute.value.path.indexOf('/company-admin') == -1 &&
      router.currentRoute.value.path.indexOf('/company-user') == -1
    "
  >
    <div class="main">無此畫面</div>
  </div>
</template>

<style>
.login {
  text-align: left;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
