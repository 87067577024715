import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import { defineAsyncComponent } from "vue";
import Loading from "../components/common/Loading.vue";

////////////////////////最高權限//////////////////////////////

const CompanyEmployer = defineAsyncComponent({
  loader: () => import("../views/admin/Employer.vue"),
  loadingComponent: Loading,
});

const Company = defineAsyncComponent({
  loader: () => import("../views/admin/Company.vue"),
  loadingComponent: Loading,
});

const CompanyOrganisation = defineAsyncComponent({
  loader: () => import("../views/admin/Organisation.vue"),
  loadingComponent: Loading,
});

const CompanyBill = defineAsyncComponent({
  loader: () => import("../views/admin/CompanyBill.vue"),
  loadingComponent: Loading,
});

const CompanyLimit = defineAsyncComponent({
  loader: () => import("../views/admin/CompanyLimit.vue"),
  loadingComponent: Loading,
});

const CompanyLineBot = defineAsyncComponent({
  loader: () => import("../views/admin/LineBot.vue"),
  loadingComponent: Loading,
});

const CompanyPermissions = defineAsyncComponent({
  loader: () => import("../views/admin/Permissions.vue"),
  loadingComponent: Loading,
});

const CompanyDepartment = defineAsyncComponent({
  loader: () => import("../views/admin/Department.vue"),
  loadingComponent: Loading,
});

const CompanyPosition = defineAsyncComponent({
  loader: () => import("../views/admin/Position.vue"),
  loadingComponent: Loading,
});

//////////////////////////公司員工最高權限////////////////////////////

const CompanyAdminCompanyBill = defineAsyncComponent({
  loader: () => import("../views/company_admin/common/CompanyBill.vue"),
  loadingComponent: Loading,
});

const CompanyAdminLineBot = defineAsyncComponent({
  loader: () => import("../views/company_admin/common/LineBot.vue"),
  loadingComponent: Loading,
});

const CompanyAdminOrganisation = defineAsyncComponent({
  loader: () => import("../views/company_admin/common/Organisation.vue"),
  loadingComponent: Loading,
});

const CompanyAdminDepartment = defineAsyncComponent({
  loader: () => import("../views/company_admin/common/Department.vue"),
  loadingComponent: Loading,
});

const CompanyAdminPosition = defineAsyncComponent({
  loader: () => import("../views/company_admin/common/Position.vue"),
  loadingComponent: Loading,
});

const CompanyAdminEmployer = defineAsyncComponent({
  loader: () => import("../views/company_admin/common/Employer.vue"),
  loadingComponent: Loading,
});

const CompanyAdminPermissions = defineAsyncComponent({
  loader: () => import("../views/company_admin/common/Permissions.vue"),
  loadingComponent: Loading,
});
//---------------------倉儲-----------------------//

const CompanyAdminWarehouseTax = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Tax.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseUnit = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Unit.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseMaterial = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Material.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseCustomer = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Customer.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehousePosition = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Position.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseShippingMethod = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/ShippingMethod.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseWarehouse = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Warehouse.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseOrder = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Order.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseSalesOrder = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/SalesOrder.vue"),
  loadingComponent: Loading,
});

const CompanyAdminWarehousePurchaseOrder = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/PurchaseOrder.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseIncomeShippingOrder = defineAsyncComponent({
  loader: () =>
    import("../views/company_admin/warehouse/WarehouseIncomeShippingOrder.vue"),
  loadingComponent: Loading,
});

const CompanyAdminWarehouseTask = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Task.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseSupplier = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Supplier.vue"),
  loadingComponent: Loading,
});

const CompanyAdminWarehouseContainer = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/Container.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehousePositionMaterialRelations = defineAsyncComponent({
  loader: () =>
    import("../views/company_admin/warehouse/PositionMaterialRelations.vue"),
  loadingComponent: Loading,
});
const CompanyAdminWarehouseHistoryPositionMaterialRelations =
  defineAsyncComponent({
    loader: () =>
      import(
        "../views/company_admin/warehouse/HistoryPositionMaterialRelations.vue"
      ),
    loadingComponent: Loading,
  });
const CompanyAdminWarehouseTaskList = defineAsyncComponent({
  loader: () =>
    import("../views/company_admin/warehouse/WarehouseTaskList.vue"),
  loadingComponent: Loading,
});
const CompanyAdminPositionAnalyze = defineAsyncComponent({
  loader: () => import("../views/company_admin/warehouse/PositionAnalyze.vue"),
  loadingComponent: Loading,
});

//-----------------------------------------------//
//////////////////////////////////////////////////////

const Home = defineAsyncComponent({
  loader: () => import("../views/Home.vue"),
  loadingComponent: Loading,
});

const routes = [
  /////////////公司人員最高權限////////////
  //-----------倉儲--------------//
  {
    path: "/company-admin/warehouse/task-list",
    name: "CompanyAdminWarehouseTaskList",
    component: CompanyAdminWarehouseTaskList,
  },
  {
    path: "/company-admin/warehouse/container",
    name: "CompanyAdminWarehouseContainer",
    component: CompanyAdminWarehouseContainer,
  },
  {
    path: "/company-admin/warehouse/position-material-relations",
    name: "CompanyAdminWarehousePositionMaterialRelations",
    component: CompanyAdminWarehousePositionMaterialRelations,
  },
  {
    path: "/company-admin/warehouse/history-position-material-relations",
    name: "CompanyAdminWarehouseHistoryPositionMaterialRelations",
    component: CompanyAdminWarehouseHistoryPositionMaterialRelations,
  },

  {
    path: "/company-admin/warehouse/supplier",
    name: "CompanyAdminWarehouseSupplier",
    component: CompanyAdminWarehouseSupplier,
  },
  {
    path: "/company-admin/warehouse/tax",
    name: "CompanyAdminWarehouseTax",
    component: CompanyAdminWarehouseTax,
  },
  {
    path: "/company-admin/warehouse/unit",
    name: "CompanyAdminWarehouseUnit",
    component: CompanyAdminWarehouseUnit,
  },
  {
    path: "/company-admin/warehouse/material",
    name: "CompanyAdminWarehouseMaterial",
    component: CompanyAdminWarehouseMaterial,
  },
  {
    path: "/company-admin/warehouse/customer",
    name: "CompanyAdminWarehouseCustomer",
    component: CompanyAdminWarehouseCustomer,
  },
  {
    path: "/company-admin/warehouse/position",
    name: "CompanyAdminWarehousePosition",
    component: CompanyAdminWarehousePosition,
  },
  {
    path: "/company-admin/warehouse/position-analyze",
    name: "CompanyAdminPositionAnalyze",
    component: CompanyAdminPositionAnalyze,
  },
  {
    path: "/company-admin/warehouse/shipping-method",
    name: "CompanyAdminWarehouseShippingMethod",
    component: CompanyAdminWarehouseShippingMethod,
  },
  {
    path: "/company-admin/warehouse/warehouse",
    name: "CompanyAdminWarehouseWarehouse",
    component: CompanyAdminWarehouseWarehouse,
  },
  {
    path: "/company-admin/warehouse/order",
    name: "CompanyAdminWarehouseOrder",
    component: CompanyAdminWarehouseOrder,
  },
  {
    path: "/company-admin/warehouse/sales-order",
    name: "CompanyAdminWarehouseSalesOrder",
    component: CompanyAdminWarehouseSalesOrder,
  },

  {
    path: "/company-admin/warehouse/purchase-order",
    name: "CompanyAdminWarehousePurchaseOrder",
    component: CompanyAdminWarehousePurchaseOrder,
  },
  {
    path: "/company-admin/warehouse/income-shipping-order",
    name: "CompanyAdminWarehouseIncomeShippingOrder",
    component: CompanyAdminWarehouseIncomeShippingOrder,
  },
  {
    path: "/company-admin/warehouse/task",
    name: "CompanyAdminWarehouseTask",
    component: CompanyAdminWarehouseTask,
  },
  //----------------------------//
  //------------設定-------------//
  {
    path: "/company-admin/permissions",
    name: "CompanyAdminPermissions",
    component: CompanyAdminPermissions,
  },
  {
    path: "/company-admin/:company_id",
    name: "CompanyAdminEmployer",
    component: CompanyAdminEmployer,
  },
  {
    path: "/company-admin",
    name: "CompanyAdminEmployer",
    component: CompanyAdminEmployer,
  },
  {
    path: "/company-admin/position",
    name: "CompanyAdminPosition",
    component: CompanyAdminPosition,
  },
  {
    path: "/company-admin/department",
    name: "CompanyAdminDepartment",
    component: CompanyAdminDepartment,
  },
  {
    path: "/company-admin/organisation",
    name: "CompanyAdminOrganisation",
    component: CompanyAdminOrganisation,
  },
  {
    path: "/company-admin/bill",
    name: "CompanyAdminCompanyBill",
    component: CompanyAdminCompanyBill,
  },
  {
    path: "/company-admin/line-bot",
    name: "CompanyAdminLineBot",
    component: CompanyAdminLineBot,
  },
  //----------------------------//
  /////////////最高權限////////////
  //------------設定-------------//
  {
    path: "/_1231admin/line-bot",
    name: "LineBot",
    component: CompanyLineBot,
  },
  {
    path: "/_1231admin/limit",
    name: "CompanyLimit",
    component: CompanyLimit,
  },
  {
    path: "/_1231admin/bill",
    name: "CompanyBill",
    component: CompanyBill,
  },
  {
    path: "/_1231admin/company",
    name: "Company",
    component: Company,
  },
  {
    path: "/_1231admin/organisation",
    name: "Organisation",
    component: CompanyOrganisation,
  },
  {
    path: "/_1231admin/department",
    name: "Department",
    component: CompanyDepartment,
  },
  {
    path: "/_1231admin/position",
    name: "Position",
    component: CompanyPosition,
  },
  {
    path: "/_1231admin/permissions",
    name: "Permissions",
    component: CompanyPermissions,
  },
  {
    path: "/_1231admin",
    name: "CompanyEmployer",
    component: CompanyEmployer,
  },
  //----------------------------//
  /////////////一般////////////
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
